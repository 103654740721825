import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: ''
            }
        }
    },

    validations: {
        form: {
            title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            warehouse: 'warehouse/warehouse',
        }),
    },
    watch:{
        warehouse(e){
            if(e){
                this.form = e;
            }else{
                this.form = {
                    title: '',
                    description: ''
                }
            }
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        sendWarehouse() {
            if(this.warehouse){
                this.$emit('changeWarehouse', this.form)
            }else{
                this.$emit('sendWarehouse', this.form)
            }
        },
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}